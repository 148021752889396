@import url("https://fonts.googleapis.com/css2?family=Nerko+One&display=swap");
* {
  margin: 0;
  overflow-x: hidden;
  color: white;
  font-family: "Nerko One", cursive;
  font-size: 1.1em;
  cursor: url('./cursor.svg'), auto;
}

a:hover {
  cursor: url('./cursordrag.svg'), auto;
}

a {
  overflow: hidden;
  text-decoration: none;
}

body {
  background-color: #63cee1;
  background-image: url("./background.jpg");
  background-position: top;
  background-repeat: repeat-x;
}

.page {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  margin: 0;
  background-image: url("./clouds.png");
  background-position: top;
  animation: animatedBackground 1000s linear infinite;
}

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 0;
  }
}

.header {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 15vh;
  min-height: 160px;
  margin: 0;
  overflow: hidden;
}

#nav {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 2px 12px;
  padding: 0px;
  overflow: hidden;
}

#nav li a {
  display: block;
  padding: 10px 22px;
  color: #ffffff;
  text-decoration: none;
  border-radius: 1.2em;
  text-shadow: 0 4px 0 rgba(0, 0, 0, .15);
  overflow: hidden;
}

#nav li a:hover {
  background-color: #ef3535;
}

.active {
  background-color: #3abb78;
}

.footer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  height: 120vh;
  margin: 0;
  background-image: url("footer-bg.png");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.footer a {
  color: #ef3535;
}

.footer > .text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95vw;
  background-color:rgba(255, 255, 255, 0.6);
  border-radius: 2em;
}

.footer span {
  margin: 3em;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 1);
  color: #6557b2;
}

.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100vw;
  margin: 0;
}

.game {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.box {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 30vw;
  height: 45vh;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  padding: 0.3em;
  border-radius: 1.3em;
  text-shadow: 0 4px 0 rgba(0, 0, 0, .15);
  color: #ffffff;
}

.red {
  background: #ef3535;
}

.orange {
  background: #f59439;
}

.yellow {
  background: #f9cc4e;
}

.purple {
  background: #6557b2;
}

.blue {
  background: #26ade4;
}

.green {
  background: #3abb78;
}